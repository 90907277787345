import React from 'react';

import { usePartner } from 'src/hooks';
import { PXLayout, Bullet } from '../components';

/**
 * @todo
 */
const SepPage = () => {
  const { color } = usePartner();

  return (
    <PXLayout hex={color} title="It's Special Enrollment" subtitle="Enrollment is limited">
      <Bullet num={1} text="SEP" />
    </PXLayout>
  );
};

export default SepPage;
