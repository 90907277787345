import React from 'react';
import SepPage from 'src/px/SepPage';
import { Page } from '../../components';

const ExplorerSEPView = ({}) => {
  return (
    <Page
      hideBanner
      context="Explorer"
      progress={0.85}
      metadata={{
        title: 'Explore Health Plans',
        description: 'Get covered for 2023',
        image: '/img/og/explorer.png',
      }}
    >
      <SepPage />
    </Page>
  );
};

export default ExplorerSEPView;
